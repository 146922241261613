import React, { useContext, useRef, useState } from "react";

import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import urljoin from "url-join";

import WidgetContentFrame from "@dashboard/WidgetContentFrame";
import WidgetContext from "@dashboard/WidgetContext";
import WidgetHeader from "@dashboard/WidgetHeader";
import WidgetSettings from "@dashboard/WidgetSettings";
import EventContext from "@event/EventContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import GrowlTable from "@shared/GrowlTable";

import MicroformWidgetSettings from "./MicroformWidgetSettings";
import PeopleEventParticipantsModal from "../../../people/event_participants/PeopleEventParticipantsModal";

const MicroformWidget = () => {
  const { editMode, values, widget } = useContext(WidgetContext);
  const { apiRoot, rootUrl } = useContext(EventContext).values;
  const growlTableRef = useRef();
  const enabled_metadata_fields = widget.widget_config.enabled_metadata_fields;
  const [editParticipantId, setEditParticipantId] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [tableItems, setTableItems] = useState(values);

  const closeEditModal = () => {
    setEditModalVisible(false);
  };
  const resetEditModal = () => {
    closeEditModal();
    setEditParticipantId(null);
  };

  const metaQuery = useQuery({
    queryKey: ["participantMeta"],
    staleTime: 10 * 1000, // 10 seconds
    cacheTime: 10 * 60 * 1000, // 10 minutes
    queryFn: ({ signal }) =>
      axios
        .get(urljoin(apiRoot, "/participants/meta"), { signal })
        .then((res) => res.data)
        .catch((error) => {
          alertHttpError(error);
        })
  });

  const renderControls = () => {
    if (!editMode) return <></>;
    return <WidgetSettings label="Microform Settings" settingsComponent={MicroformWidgetSettings} />;
  };

  const updateParticipant = (participant) => {
    let newTableItems = tableItems.map((p) => {
      if (p.gid === participant.gid) {
        return participant;
      }
      return p;
    });
    setTableItems(newTableItems);
  };

  const columns = [
    {
      field: "override_name_first",
      headerName: "First Name",
      editable: true,
      valueGetter: (params) => {
        return params?.row?.name_first;
      },
      valueSetter: (params) => {
        params.row.name_first = params.value;
        return params.row;
      }
    },
    {
      field: "override_name_last",
      headerName: "Last Name",
      editable: true,
      valueGetter: (params) => {
        return params?.row?.name_last;
      },
      valueSetter: (params) => {
        params.row.name_last = params.value;
        return params.row;
      }
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 200
    },
    {
      field: "override_company",
      headerName: "Company",
      editable: true,
      valueGetter: (params) => {
        return params?.row?.company;
      },
      valueSetter: (params) => {
        params.row.company = params.value;
        return params.row;
      }
    },
    {
      field: "type",
      headerName: "Participant Type",
      renderCell: (params) => <span className="capitalize">{params.value}</span>
    },
    {
      field: "status",
      headerName: "Status",
      editable: true,
      type: "singleSelect",
      valueOptions: ["Preregistered", "Registered", "Cancelled", "Declined", "Disallowed"],
      valueGetter: (params) => {
        const str = params.value;
        return str.charAt(0).toUpperCase() + str.slice(1);
      }
    },
    metaQuery?.data?.metadataFields.flatMap((mf) => ({
      field: mf.slug,
      headerName: mf.label,
      editable: true,
      valueGetter: (params) => {
        return params?.row?.metadata?.find((md) => md?.field_slug == mf.slug)?.value;
      },
      valueSetter: (params) => {
        if (params.row.metadata.find((md) => md.field_slug == mf.slug) !== undefined) {
          params.row.metadata.find((md) => md.field_slug == mf.slug).value = params.value;
        } else {
          params.row.metadata.push({ field_slug: mf.slug, value: params.value });
        }
        return params.row;
      }
    })),
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      minWidth: 180,
      getActions: (params) => [renderEditAction(params.row.id)]
    }
  ].flat();

  const defaultColumnVisibility = Object.fromEntries([
    ["vault_saved_at", false],
    ...metaQuery.data.metadataFields.map((mf) => [mf.slug, false])
  ]);

  const editPersonClick = (id) => {
    setEditParticipantId(id);
    setEditModalVisible(true);
  };

  const renderEditAction = (id) => {
    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            editPersonClick(id);
          }}
        >
          Edit
        </span>
      </>
    );
  };

  const renderEditModal = () => {
    if (!editParticipantId) {
      return <></>;
    }

    return (
      <PeopleEventParticipantsModal
        apiRoot={apiRoot}
        addIndividual={() => {}}
        enabledMetadataFields={enabled_metadata_fields}
        individuals={[]}
        isMicroform={true}
        participantId={editParticipantId}
        closeModal={closeEditModal}
        modalVisible={editModalVisible}
        metadataFields={metaQuery.data.metadataFields}
        regFields={metaQuery.data.regFields}
        resetModal={resetEditModal}
        rootUrl={rootUrl}
        tags={metaQuery.data.tags}
        types={metaQuery.data.types}
        updateFunc={(participant) => {
          updateParticipant(participant);
        }}
      />
    );
  };

  const saveCell = (params) => {
    let formUrl = urljoin(apiRoot, "/participants", `/${params.row.id}`);
    const formData = new FormData();
    formData.set("participant_gid", params.row.gid);
    if (
      params.field === "override_name_first" ||
      params.field === "override_name_last" ||
      params.field === "override_company"
    ) {
      formData.set(`participant[${params.field}]`, params.formattedValue);
    } else {
      formData.set(`participant[metadata][${params.field}]`, params.formattedValue);
    }
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: formUrl,
      method: "PATCH",
      data: formData
    })
      .then((response) => {
        if (response.data.error === null) {
          alertSuccess(`Participant updated successfully`);
          updateParticipant(response.data.participant);
        } else {
          alertError(response.data.error);
        }
      })
      .catch((error) => {
        alertError(error);
      });
  };

  return (
    <WidgetContentFrame fluidHeight>
      <WidgetHeader />
      <div className="mt-2">
        <GrowlTable
          cellSelection={true}
          checkboxSelection
          onCellEditStop={saveCell}
          columns={columns}
          componentRef={growlTableRef}
          defaultColumnVisibility={defaultColumnVisibility}
          items={tableItems}
          tableName={`${widget.gid}-participants`}
          toolbarControls={[]}
          pageSizeInitial={10}
          pageSizeOptions={[5, 10, 25]}
        />
      </div>
      {renderEditModal()}
      {renderControls()}
    </WidgetContentFrame>
  );
};

export default MicroformWidget;
